import React from 'react';
import {
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  LinkTo,
  Actions,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import Audit from '../../assets/images/icons/audit.svg';
import BlockchainA from '../../assets/images/icons/blockchain-a.svg';
import BlockchainB from '../../assets/images/icons/blockchain-b.svg';
import Flexible from '../../assets/images/icons/flexible.svg';
import Gear from '../../assets/images/icons/gear-forward.svg';
import Shield from '../../assets/images/icons/shield-check.svg';

const ProvableContests = () => {
  return (
    <PageLayout>
      <SEO
        title="Dragonchain - Provably Fair Contest System"
        description="Looking for a fair way to draw winners for your give-away? Dragonchain’s Provably Fair System is
        impossible to profitably cheat."
      />
      <Layout dark background="blue">
        <Hero type="code">
          <div className="caption">Provable Contests</div>
          <Title color="white">Life’s Not Fair, But Your Contests Are!</Title>
          <Description color="blue-light">
            Looking for a fair way to draw winners for your give-away? Dragonchain’s Provably Fair System is
            impossible to profitably cheat.
          </Description>
        </Hero>
      </Layout>

      <LayoutBlock separator>
        <Container>
          <Row gap="small">
            <Column span={6}>
              <Section title="Features" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                Icon: Audit,
                title: 'Auditability',
                body:
                  'Measurable and immutable proof the selection was fair. Anyone can audit for themselves who the winner is once selected.',
              },
              {
                Icon: BlockchainA,
                title: 'Provably Fair Selection',
                body:
                  'Winning selection is determined by the hash of a predefined bitcoin and ethereum block, making it unprofitable to game the system.',
              },
              {
                Icon: BlockchainB,
                title: 'Future hash',
                body:
                  'The hash of a future Bitcoin and Ethereum block are unknowable, making the selection algorithm completely random. Secure, hundreds of millions of dollars.',
              },
              {
                Icon: Flexible,
                title: 'Versatile',
                body:
                  'Run contests, give-aways, or just let us decide which country you’re going to visit next? Time to put your creative marketing hat on!',
              },
              {
                Icon: Gear,
                title: 'Integrations',
                body: 'Current integrations include Beaxy.',
              },
              {
                Icon: Shield,
                title: 'Hassle-free & trustworthy',
                body:
                  'Run your contests the same way you always do, except ensure participants that winners will be drawn provably fair.',
              },
            ]}
          />
          <Row gap="large">
            <Column span={12}>
              <Accordion title="Frequently Asked Questions">
                <Accordion.Item title="I’d like to see a more extensive magic explanation behind how all of this works.">
                  <LinkTo type="route" blank to="https://den.social/l/hash/5abd6fcf4828411682c4666e20dcba85/">
                    Here you go!
                  </LinkTo>
                </Accordion.Item>
                <Accordion.Item title="Can we integrate this Provably Fair System with our current software?">
                  Yes, all of our products can be integrated into what you’re already using, or what you’re
                  planning to use in the future.
                </Accordion.Item>
                <Accordion.Item title="Can Dragonchain do the integration for us?">
                  Our developers can certainly do that for you, or with you.
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <Section title="Create your own provably fair contest.">
                Looking for additional custom features or ways to integrate the Provably Fair System? Schedule
                a free consultation with the creators.
              </Section>
            </Column>
            <Column span={6} right middle>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default ProvableContests;
